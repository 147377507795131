import React, { useContext } from "react";
import "./index.css";
import { ThemeContext } from "../theme-context";
import { Link } from "react-router-dom";

const Footer = () => {
  const { boyTheme, toggleTheme } = useContext(ThemeContext);

  return (
    <footer>
      <div className="footer-content">
        <ul className="footer-list">
          <Link to="/terms">
            <li>Terms of use</li>
          </Link>
          <Link to="/privacy">
            <li>Privacy Policy</li>
          </Link>
          <li>2257</li>
          <li>Refund Policy</li>
          <Link to="/contact">
            <li>Contact/Support</li>
          </Link>
        </ul>
        <div className="footer-cp">
          <span className="footer-copyright">
            2024 &copy; NUDEHUB. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
