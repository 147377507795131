import React, { useEffect } from "react";
import "./index.css";
import TermItem from "./components/term-item";
import arrow from "../../assets/loginPage/ArrowUUpLeft.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const termsOfService = [
  {
    id: 1,
    title: "Introduction",
    content:
      "Our website offers a service that provides users with the ability to create and modify images using our tools. We do not claim ownership of the resulting content. Our website is intended for personal, non-commercial use.",
  },
  {
    id: 2,
    title: "Eligibility",
    content:
      "To use our website, you must be at least 18 years of age. If you are under 18 years of age, then you are not permitted to use our website.",
  },
  {
    id: 3,
    title: "User Responsibility",
    content:
      "You are solely responsible for the images you create or modify using our website. While using our service, you may not use images with other people without their permission. Otherwise, you agree to bear full responsibility for the creation of such materials. You agree that your images do not infringe upon the copyrights, trademarks, or other intellectual property rights of any third-party. You must provide accurate and up-to-date information when using our website.",
  },
  {
    id: 4,
    title: "Prohibited Uses",
    content:
      "You agree that you will not use our website for any illegal or unauthorized purposes. This includes but is not limited to the creation or sharing of objectionable content, harassment of others, and infringement of intellectual property rights.",
  },
  {
    id: 5,
    title: "Purchasing Content",
    content:
      "If you purchase any content from our website, you agree to pay the amount specified and to provide accurate billing information. We reserve the right to cancel, refuse, or terminate any purchase.",
  },
  {
    id: 6,
    title: "Account Registration",
    content:
      "To register for an account, you must provide an email address that you have access to. You are responsible for maintaining the confidentiality of your account information and password. You agree to notify us immediately if any unauthorized access or use of your account occurs.",
  },
  {
    id: 7,
    title: "Content Ownership and Responsibility",
    content:
      "We do not claim ownership of the content you create or modify using our website. You retain all ownership rights to your content, and you are solely responsible for the content you create. We reserve the right to remove or delete any content that violates our terms of service. Any actions related to these categories: (Child sexual abuse materials; Incest; Bestiality; Rape or any other non-consensual sexual behavior; Non-consensual mutilation of a person or body part) are forbidden and restricted. Users are strictly prohibited from this. Filters are employed to prevent users from engaging in such actions.",
  },
  {
    id: 8,
    title: "Termination",
    content:
      "We reserve the right to terminate your access to our website, without notice, for any violation of our terms of service.",
  },
  {
    id: 9,
    title: "Privacy Policy",
    content:
      "Our privacy policy outlines how we collect and use your personal information. By using our website, you agree to our privacy policy.",
  },
  {
    id: 10,
    title: "Changes to the Terms of Service",
    content:
      "We reserve the right to make changes to our terms of service at any time. We will notify our users of any changes, and they will be effective immediately. Your continued use of our website constitutes acceptance of our terms of service.",
  },
];

const TermsOfService = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Вернёт на предыдущую страницу
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <div className="terms-contaienr">
        <div className="terms-title-container">
          <p className="terms-title">
            Terms Of <span className="color">Service</span>
          </p>
        </div>
        <div className="terms-content">
          {termsOfService.map((item) => (
            <TermItem
              key={item.id}
              id={item.id}
              title={item.title}
              answer={item.content}
            />
          ))}
          <div className="terms-button-container">
            <button
              type="button"
              className="button-back"
              onClick={handleBackClick}
            >
              <img src={arrow} alt={t("button_back_alt")} />
              {t("button_back")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfService;
