import React from "react";

const TermItem = ({ id, title, answer }) => {
  return (
    <div>
      <h3 className="color terms-item-title">
        {id}.{title}
      </h3>
      <p className="terms-item-answer">{answer}</p>
    </div>
  );
};

export default TermItem;
