// ModalEditor.jsx
import React from "react";

const ModalEditor = ({ isModalOpen, onClose, onConfirm }) => {
  if (!isModalOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Внимание!</h2>
        <p>Все изменения будут удаленны безвозвратно</p>
        <button onClick={onClose}>Закрыть</button>
        <button
          onClick={() => {
            onConfirm();
            onClose(); // Optionally close the modal after confirming
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default ModalEditor;
