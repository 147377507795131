// import { store, authActions } from "_store";
import store from "../store";
import { authActions } from "../store";

const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };

    if (body && !(body instanceof FormData)) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    } else if (body instanceof FormData) {
      requestOptions.body = body;
    }

    return fetch(url, requestOptions)
      .then((response) => {
        // console.log("Ответ от fetch:", response);
        return handleResponse(response);
      })
      .catch((error) => {
        console.error("Ошибка fetchWrapper:", error);
        throw error;
      });
  };
}

// helper functions

function authHeader(url) {
  // возвращает заголовок авторизации с JWT, если пользователь авторизован, и запрос идет на URL API
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith("https://api.nudehub.art/api/");
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function authToken() {
  const tokenFromStore = store.getState().auth.value?.token;
  const authFromLocalStorage = JSON.parse(localStorage.getItem("auth"));
  if (
    authFromLocalStorage &&
    authFromLocalStorage.token &&
    authFromLocalStorage.expiration > Date.now()
  ) {
    return authFromLocalStorage.token;
  }
  return tokenFromStore;
}

async function handleResponse(response) {
  const contentType = response.headers?.get("content-type");
  //console.log("Content-Type:", contentType); // Логируем Content-Type для отладки

  if (response.ok) {
    if (
      contentType?.includes("image/") ||
      contentType === "application/octet-stream"
    ) {
      // Возвращаем Blob для изображений и бинарных данных
      const blob = await response.blob();
      //console.log("Получен Blob изображения");
      return blob;
    } else if (contentType?.includes("application/json")) {
      // Возвращаем JSON для остальных типов данных
      const data = await response.json();
      // console.log("Получен JSON:", data);
      return data;
    } else {
      // Возвращаем текст для остальных типов данных
      if (response.status == 202) {
        return { status: 202 };
      }
      const text = await response.text();
      return text;
    }
  }

  // Обработка ошибок
  if ([401, 403].includes(response.status) && authToken()) {
    const logout = () => {
      store.dispatch(authActions.logout());
      localStorage.removeItem("auth");
    };
    logout();
  }

  const error = (await response.json())?.message || response.status;
  console.error("Ошибка:", error);
  return Promise.reject(error);
}

export default fetchWrapper;
