import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { packagesActions } from "../../store";
import { useNavigate } from "react-router-dom";
import fullHeart from "../../assets/pricing/fullHeart.svg";
import emptyHeart from "../../assets/pricing/emptyHeart.svg";
import "./index.css";
import { Trans, useTranslation } from "react-i18next";

const Pricing = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const containerRef = useRef(null);
  const isScrollingRef = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { items = [] } = useSelector((state) => state.package);

  useEffect(() => {
    const currentLanguage = i18n.language;
    dispatch(packagesActions.fetchPackages(currentLanguage));
  }, [dispatch, i18n.language]);

  const handleDotClick = (index) => {
    isScrollingRef.current = true;
    const cardWidth = containerRef.current.firstChild.clientWidth;
    containerRef.current.scrollTo({
      left: cardWidth * index,
      behavior: "smooth",
    });

    setTimeout(() => {
      isScrollingRef.current = false;
      setActiveIndex(index);
    }, 500);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isScrollingRef.current) return;

      const cardWidth = containerRef.current.firstChild.clientWidth;
      const scrollPosition = containerRef.current.scrollLeft;
      const index = Math.round(scrollPosition / cardWidth);

      setActiveIndex(index);
    };

    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleCardClick = (id) => {
    navigate(`/package/${id}`);
  };

  return (
    <section className="pricing-section" id="pricing">
      <div className="pricing-container">
        <div className="title-container">
          <h1 className="pricing-title">
            <Trans
              i18nKey="pricing_plans"
              components={{
                span: <span style={{ color: "var(--primary-color)" }} />,
              }}
            />
          </h1>
        </div>

        <div className="cards-container">
          <div className="pricing-cards" ref={containerRef}>
            {items.map((item, index) => (
              <div
                className={`pricing-card ${
                  item.name === "Premium" ? "elite-pricing-card" : ""
                }`}
                key={index}
              >
                <div className="lifetime-badge">{item.type}</div>
                <div className="card-title">{item.name}</div>
                <div className="card-subtitle">{item.tokens} </div>
                <ul className="card-features">
                  {/* {item && item.includes ? (
                    item.includes.map((feature, i) => (
                      <li className="card-feature" key={i}>
                        <img
                          src={fullHeart}
                          alt={i < 4 ? "full heart" : "empty heart"}
                        />
                        {feature}
                      </li>
                    ))
                  ) : (
                    <></>
                  )} */}
                  {item && item.features ? (
                    item.features.map((feature, i) => (
                      <li className="card-feature" key={i}>
                        <img
                          src={fullHeart}
                          alt={i < 4 ? "full heart" : "empty heart"}
                        />
                        {feature.name}
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ul>
                <div className="card-bottom">
                  <div
                    style={{ cursor: "pointer" }}
                    className="card-price"
                    onClick={() => handleCardClick(item.id)}
                  >
                    <span className="old-price">300</span>
                    <span className="new-price">{item.cost}</span>
                  </div>
                  <div className="">
                    <p className="card-bottom-text">One credit - One picture</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="dots-container mobile">
            {items.map((_, index) => (
              <div
                key={index}
                className={`dot ${index === activeIndex ? "active-dot" : ""}`}
                onClick={() => handleDotClick(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
