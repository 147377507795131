import React from "react";

const PrivacyItem = ({ title, answer }) => {
  return (
    <div>
      <h3 className="color privacy-item-title">{title}</h3>
      <p className="privacy-item-answer">{answer}</p>
    </div>
  );
};

export default PrivacyItem;
