// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color {
  color: var(--primary-color);
}

.terms-contaienr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(3 * var(--index));
  margin-bottom: calc(3 * var(--index));
}

.terms-title {
  font-size: 24px;
}

.terms-item-title {
  font-size: 24px;
  font-weight: 500;
}

.terms-item-answer {
  font-size: 20px;
  font-weight: 400;
}

.terms-content {
  width: 70%;
  margin: 0 auto;
}

.terms-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 105px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/terms-of-service/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kCAAkC;EAClC,qCAAqC;AACvC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".color {\n  color: var(--primary-color);\n}\n\n.terms-contaienr {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-top: calc(3 * var(--index));\n  margin-bottom: calc(3 * var(--index));\n}\n\n.terms-title {\n  font-size: 24px;\n}\n\n.terms-item-title {\n  font-size: 24px;\n  font-weight: 500;\n}\n\n.terms-item-answer {\n  font-size: 20px;\n  font-weight: 400;\n}\n\n.terms-content {\n  width: 70%;\n  margin: 0 auto;\n}\n\n.terms-button-container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  margin-top: 105px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
