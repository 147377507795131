import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./components/index.css";
import AccountInfo from "./components/account-info";
import logoutButton from "../../assets/account/logout.svg";
import AccountTable from "./components/account-table";
import { authActions, packagesActions, userActions } from "../../store"; // Импортируем packagesActions
import { Link } from "react-router-dom";
import "./index.css";
import { ThemeContext } from "../../components/theme-context";

const AccountPage = () => {
  const { boyTheme, toggleTheme } = useContext(ThemeContext);

  const auth = useSelector((x) => x.auth.value);
  // Добавляем состояние для страницы
  const [currentPage, setCurrentPage] = useState(0);
  const { transactions = { content: [] } } = useSelector(
    (state) => state.package
  );
  const userInfo = useSelector((state) => state.users.item);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("Fetching transactions...");
  //   dispatch(packagesActions.fetchTransactions(0));
  // }, []);

  const handleLogout = async () => {
    await dispatch(authActions.logout());
  };

  useEffect(() => {
    dispatch(packagesActions.fetchTransactions(currentPage));
    dispatch(userActions.fetchUserInfo());
  }, [dispatch, currentPage]);

  useEffect(() => {
    document.body.classList.toggle("boy-theme", boyTheme);
  }, [boyTheme]);

  const loadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <section id="account">
      <div className="account-title">
        <h1 className="account-header-title">
          Account <span className="account-header-title-span">Info</span>mation
        </h1>
      </div>
      <div className="account-header">
        <div className="account-header-left">
          <AccountInfo user={userInfo} />
        </div>
        <div className="account-header-right">
          {userInfo?.subscriptionExpiresAt && userInfo?.productName && (
            <p className="account-header-right-expires">
              Until {userInfo?.subscriptionExpiresAt}
            </p>
          )}
          {userInfo?.productName ? (
            <p className="account-header-right-button">Cancel</p>
          ) : (
            <></>
          )}
          {userInfo?.productName ? (
            <>
              <p className="account-header-right-expires">
                <span
                  className={`${
                    userInfo?.productName === "Premium"
                      ? "account-header-plan-name-premium"
                      : ""
                  }`}
                >
                  {userInfo?.productName}
                </span>{" "}
                until
                {userInfo?.subscriptionExpiresAt}
              </p>
              <p className="account-header-right-button">Cancel</p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <AccountTable transactions={transactions} />{" "}
        {/* Передаем транзакции в AccountTable */}
      </div>
      <div>
        {transactions?.hasNext && (
          <button className="load-more-button" onClick={loadMore}>
            Load MOre
          </button>
        )}
      </div>
    </section>
  );
};

export default AccountPage;
