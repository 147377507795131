import { useNavigate } from "react-router-dom";
import AuthForm from "./AuthForm";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/index";
import toast from "react-hot-toast";

const LoginForm = ({ setActiveForm }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useSelector((state) => state.alert.value);

  const onSubmit = async ({ email, password }) => {
    try {
      await dispatch(authActions.login({ email, password })).unwrap();
      navigate("/account");
    } catch (error) {
      console.error("Login failed:", error); // Для лучшего отлова ошибок
      // toast.error("Login failed, please check your credentials.");
    }
  };

  return (
    <>
      <AuthForm
        isSignUp={false}
        onSubmit={onSubmit}
        error={alert}
        setActiveForm={setActiveForm} // Передаем setActiveForm для переключения форм
      />
    </>
  );
};

export default LoginForm;
