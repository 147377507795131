import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/main";
import Login from "./pages/login";
import AccountPage from "./pages/account";
import ImageDownloader from "./pages/image-downloader";
import PrivateRoute from "./components/PrivateRoute";
import { Toaster } from "react-hot-toast";
import Packages from "./pages/package";
import ImageWithMask from "./components/TestImage";
import TermsOfService from "./pages/terms-of-service";
import Privacy from "./pages/privacy";
import Contact from "./pages/contact";
import Thanks from "./pages/thanks";

function App() {
  return (
    // <ImageWithMask />
    <BrowserRouter>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header />
        <Toaster />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/account" element={<AccountPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/package/:id" element={<Packages />} />
          </Route>
          {/* <Route path="/package/:id" element={<Packages />} /> */}
          <Route element={<PrivateRoute />}>
            <Route path="/editor" element={<ImageDownloader />} />
          </Route>
          <Route path="/terms" element={<TermsOfService />}></Route>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="thanks" element={<Thanks />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
