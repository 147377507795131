import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchWrapper from "../helpers/fetch-wrapper";

const name = "imageOperations";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const imageOperationsActions = { ...slice.actions, ...extraActions };
export const imageOperationsReducer = slice.reducer;

function createInitialState() {
  return {
    operations: {},
    hasNext: false,
    operationImages: {}, // Новый объект для хранения изображений
    currentOperation: null,
    currentMask: null,
    status: "idle",
    error: null,
    hasNext: false,
  };
}

function createReducers() {
  return {
    setOperations: (state, action) => {
      if (action.payload.replace) {
        state.operations.content = action.payload.content;
        // console.log("Operations replaced:", state.operations.content);
      } else {
        state.operations.content = state.operations.content
          ? [...state.operations.content, ...action.payload]
          : action.payload;
        // console.log("Operations appended:", state.operations.content);
      }
      state.hasNext = action.payload.hasNext;
      state.status = "succeeded";
    },
    setHasNext: (state, action) => {
      state.hasNext = action.payload;
    },

    setCurrentOperation: (state, action) => {
      state.currentOperation = action.payload;
      state.status = "succeeded";
    },
    setOperationImage: (state, action) => {
      state.operationImages[action.payload.id] = action.payload.imageUrl;
    },
    setLoading: (state) => {
      state.status = "loading";
    },
    setError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    setCurrentMask: (state, action) => {
      state.currentMask = action.payload; // Сохраняем URL маски
    },
  };
}

// function createReducers() {
//   return {
//     setOperations: (state, action) => {
//       const sortedOperations = [
//         ...state.operations.content,
//         ...action.payload.content,
//       ].sort(
//         (a, b) => b.id - a.id // Сортировка по id в убывающем порядке
//       );
//       state.operations.content = sortedOperations;
//       state.hasNext = action.payload.hasNext;
//       state.status = "succeeded";
//     },
//     setCurrentOperation: (state, action) => {
//       state.currentOperation = action.payload;
//       state.status = "succeeded";
//     },
//     setOperationImage: (state, action) => {
//       state.operationImages[action.payload.id] = action.payload.imageUrl;
//     },
//     setLoading: (state) => {
//       state.status = "loading";
//     },
//     setError: (state, action) => {
//       state.status = "failed";
//       state.error = action.payload;
//     },
//   };
// }

function createExtraActions() {
  return {
    fetchImageOperations: createFetchImageOperations(),
    uploadImage: createUploadImage(),
    fetchCurrentOperation: createFetchCurrentOperation(),
    fetchMask: createFetchMask(),
    submitOperation: createSubmitOperation(),
  };
}

function createFetchCurrentOperation() {
  return createAsyncThunk(
    `${name}/fetchCurrentOperation`,
    async function ({ id, size, toLocalStorage = false }, { dispatch }) {
      try {
        const url = `https://api.nudehub.art/api/operations/${id}?size=${size}`;
        const response = await fetchWrapper.get(url);

        if (response instanceof Blob) {
          const imageUrl = URL.createObjectURL(response);
          dispatch(imageOperationsActions.setOperationImage({ id, imageUrl }));
          dispatch(
            imageOperationsActions.setCurrentOperation({ id, imageUrl })
          );
          return toLocalStorage ? response : imageUrl;
        } else {
          dispatch(imageOperationsActions.setCurrentOperation(response));
          return response; // Ensures the promise resolves with the response
        }
      } catch (error) {
        console.error("Ошибка в fetchCurrentOperation:", error);
        dispatch(imageOperationsActions.setError(error.toString()));
        throw error; // Ensures the promise rejects in case of an error
      }
    }
  );
}

function createFetchMask() {
  return createAsyncThunk(
    `${name}/fetchMask`,
    async function ({ id, toLocalStorage = false }, { dispatch }) {
      try {
        const url = `https://api.nudehub.art/api/operations/${id}?size=MASK`;
        const response = await fetchWrapper.get(url);
        console.log("Response from fetchMask:", response);
        if (response instanceof Blob) {
          const imageUrl = URL.createObjectURL(response);
          console.log("Mask image URL:", imageUrl);
          dispatch(imageOperationsActions.setCurrentMask({ id, imageUrl }));
          return toLocalStorage ? response : imageUrl;
        }
      } catch (error) {
        console.error("Ошибка в fetchMask:", error);
        dispatch(imageOperationsActions.setError(error.toString()));
      }
    }
  );
}

// Экшн для получения операций с изображениями
function createFetchImageOperations() {
  return createAsyncThunk(
    `${name}/fetchImageOperations`,
    async function (
      { page, size, bodyType = "WOMEN", replace = false },
      { dispatch }
    ) {
      try {
        dispatch(imageOperationsActions.setLoading());

        // console.log("Fetching operations:", { page, size, replace }); // Логирование

        const response = await fetchWrapper.get(
          `https://api.nudehub.art/api/operations?page=${page}&size=${size}&bodyType=${bodyType}`
        );

        console.log("Fetched operations:", response.content); // Логирование

        dispatch(
          imageOperationsActions.setOperations(
            response.content
            // content: response.content,
            // hasNext: response.hasNext,
            // replace, // Передаем флаг replace в редьюсер
          )
        );
        dispatch(imageOperationsActions.setHasNext(response.hasNext));

        localStorage.setItem("operations", JSON.stringify(response));
        console.log("Operations saved to localStorage:", response);
        return response;
      } catch (error) {
        dispatch(imageOperationsActions.setError(error.toString()));
      }
    }
  );
}

// Экшн для отправки изображения
function createUploadImage() {
  return createAsyncThunk(
    `${name}/uploadImage`,
    async function ({ file, bodyType = "WOMEN" }, { dispatch }) {
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("bodyType", bodyType);

      try {
        dispatch(imageOperationsActions.setLoading()); // Отмечаем начало загрузки

        const response = await fetchWrapper.post(
          "https://api.nudehub.art/api/operations",
          formData
        );

        console.log("Response from uploadImage:", response);

        // Сохраняем ответ от сервера
        dispatch(imageOperationsActions.setCurrentOperation(response));

        // Создаем URL для загруженного изображения
        const imageUrl = URL.createObjectURL(file);

        // Обновляем состояние изображения
        dispatch(
          imageOperationsActions.setOperationImage({
            id: response.id,
            imageUrl,
          })
        );

        // Обновляем список операций полностью
        dispatch(
          imageOperationsActions.fetchImageOperations({
            page: 0,
            size: 10,
            replace: true, // Указываем, что нужно перезаписать список операций
          })
        );
      } catch (error) {
        dispatch(imageOperationsActions.setError(error.toString())); // Устанавливаем ошибку в состояние
      }
    }
  );
}

function createSubmitOperation() {
  return createAsyncThunk(
    `${name}/submitOperation`,
    async function ({ operationId, bodyType, maskBlob }, { dispatch }) {
      const formData = new FormData();
      formData.append("bodyType", bodyType);
      formData.append("mask", maskBlob);

      try {
        dispatch(imageOperationsActions.setLoading());

        const response = await fetchWrapper.post(
          `https://api.nudehub.art/api/operations/${operationId}/submit`,
          formData
        );

        // Если response - это Blob, нужно обработать его отдельно
        const responseData = await response; // или response.json(), если ожидается JSON

        // Теперь отправляем только сериализуемые данные в Redux
        dispatch(
          imageOperationsActions.setCurrentOperation({
            response: responseData, // или обработанные данные
          })
        );

        // console.log("Operation submitted successfully:", responseData);

        return responseData;
      } catch (error) {
        dispatch(imageOperationsActions.setError(error.toString()));
        throw error;
      }
    }
  );
}
