import { configureStore } from "@reduxjs/toolkit";

import { alertReducer } from "./alert.slice";
import { authReducer } from "./auth.slice";
import { usersReducer } from "./users.slice";
import { packagesReducer } from "./package.slice";
import { imageOperationsReducer } from "./image-operations.slice";
import { supportReducer } from "./support.slice.js";

export * from "./alert.slice";
export * from "./auth.slice";
export * from "./users.slice";
export * from "./package.slice";
export * from "./image-operations.slice.js";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    users: usersReducer,
    package: packagesReducer,
    imageOperations: imageOperationsReducer,
    support: supportReducer,
  },
});

export default store;
