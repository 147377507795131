import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";

// Функция для определения языка по геолокации
const detectLanguageByGeo = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const countryCode = response.data.country_code;

    // Определение языка на основе кода страны
      switch (countryCode) {
          case "US":
              return "en";
          case "DE":
              return "de";
          case "ES":
              return "es";
          case "PT":
              return "pt";
          default:
              return "en";
      }
  } catch (error) {
      console.error("Ошибка при определении геолокации:", error);
      return "en"; // fallback язык по умолчанию
  }
};

// Настройка i18n
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "es", "pt"], // Добавлен украинский язык "uk"
    fallbackLng: "en",
    detection: {
      order: ["queryString", "cookie", "localStorage", "navigator"],
      caches: ["cookie"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      async: true,
      checkWhitelist: true,
      checkForSimilarInWhitelist: true,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
  });

// Переопределение языка после загрузки геолокации
detectLanguageByGeo().then((detectedLng) => {
  i18n.changeLanguage(detectedLng);
});

export default i18n;
