import React, { useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/loginPage/ArrowUUpLeft.svg";
import { useDispatch } from "react-redux";
import { supportActions } from "../../store/support.slice";

const Contact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [reportText, setReportText] = useState("");
  const dispatch = useDispatch();
  const { sendToSupport } = supportActions;

  const handleBackClick = () => {
    navigate("/");
  };

  const handleSendClick = () => {
    dispatch(sendToSupport({ email, reportText }));
  };

  return (
    <section id="contact-section">
      <div className="contact-container">
        <div className="contact-title-container">
          <p className="contact-title">
            Contact and <span className="color">Support</span>
          </p>
        </div>
        <div className="contact-content">
          <input
            className="input"
            type="text"
            placeholder={t("form_email_placeholder")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            className="describe input"
            type="text"
            placeholder={"Describe your issue"}
            onChange={(e) => setReportText(e.target.value)}
          />
          <div className="contact-buttons-box">
            <button
              type="button"
              className="button-back"
              onClick={handleBackClick}
            >
              <img src={arrow} alt={t("button_back_alt")} />
              {t("button_back")}
            </button>
            <button
              type="submit"
              className="button-login"
              onClick={handleSendClick}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
