// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: var(--primary-color);
}

.footer-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  gap: 15px;
}

.footer-list li {
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.footer-cp {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-copyright {
  font-size: 12.8px;
  font-weight: 700;
  color: white;
  text-align: center;
}

@media (max-width: 1024px) {
  .footer-list {
    flex-direction: column;
  }

  .footer-list li {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/index.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,UAAU;EACV,cAAc;EACd,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["footer {\n  background-color: var(--primary-color);\n}\n\n.footer-content {\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.footer-list {\n  display: flex;\n  align-items: center;\n  list-style-type: none;\n  padding: 0;\n  margin: 20px 0;\n  gap: 15px;\n}\n\n.footer-list li {\n  color: white;\n  font-size: 16px;\n  font-weight: 700;\n  cursor: pointer;\n}\n\n.footer-cp {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.footer-copyright {\n  font-size: 12.8px;\n  font-weight: 700;\n  color: white;\n  text-align: center;\n}\n\n@media (max-width: 1024px) {\n  .footer-list {\n    flex-direction: column;\n  }\n\n  .footer-list li {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
