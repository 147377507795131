import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchWrapper from "../helpers/fetch-wrapper";
import { alertActions } from "./alert.slice";

const name = "subscription";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const subscriptionActions = { ...slice.actions, ...extraActions };
export const subscriptionReducer = slice.reducer;

function createInitialState() {
  return {
    status: "idle",
    error: null,
  };
}

function createReducers() {
  return {
    setLoading: (state) => {
      state.status = "loading";
    },
    setError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  };
}

function createExtraActions() {
  return {
    cancelSubscription: createCancelSubscription(),
    reactivate: createReactivate(),
  };

  function createCancelSubscription() {
    return createAsyncThunk(
      `${name}/cancelSubscription`,
      async function (_, { dispatch }) {
        try {
          dispatch(subscriptionActions.setLoading(true));
          const response = await fetchWrapper.delete(
            "http://api.nudehub.art/api/subscriptions"
          );
          dispatch(subscriptionActions.setLoading(false));
          return response;
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "An unknown error occurred";
          dispatch(subscriptionActions.setError(errorMessage));
          dispatch(alertActions.error({ message: errorMessage }));
        }
      }
    );
  }

  function createReactivate() {
    return createAsyncThunk(
      `${name}/reactivate`,
      async function (_, { dispatch }) {
        try {
          dispatch(subscriptionActions.setLoading(true));
          const response = await fetchWrapper.put(
            "http://api.nudehub.art/api/subscriptions/reactivate"
          );
          dispatch(subscriptionActions.setLoading(false));
          return response;
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "An unknown error occurred";
          dispatch(subscriptionActions.setError(errorMessage));
          dispatch(alertActions.error({ message: errorMessage }));
        }
      }
    );
  }
}
