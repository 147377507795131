import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { alertActions } from "./alert.slice";
import history from "../helpers/history";
import fetchWrapper from "../helpers/fetch-wrapper";
import toast from "react-hot-toast";
// create slice

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    value: JSON.parse(localStorage.getItem("auth")),
  };
}

function createReducers() {
  return {
    setAuth,
  };

  function setAuth(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    login: login(),
    logout: logout(),
    resetPassword: resetPassword(),
  };

  function login() {
    return createAsyncThunk(
      `/login`,
      async function ({ email, password }, { dispatch }) {
        dispatch(alertActions.clear());

        try {
          // Формируем новый URL с параметрами запроса
          const url = `https://api.nudehub.art/api/users/sign-in?email=${encodeURIComponent(
            email
          )}&password=${encodeURIComponent(password)}`;

          const response = await fetchWrapper.get(url);

          // если ответ успешный, запишите пользователя в состояние Redux
          dispatch(authActions.setAuth(response));

          // сохраните детали пользователя и токен JWT в localStorage
          localStorage.setItem("auth", JSON.stringify(response));

          // navigate("/account");
        } catch (error) {
          // извлеките сообщение из ошибки и передайте его в alert
          const errorMessage = error || "An unknown error occurred";

          dispatch(alertActions.error({ message: errorMessage }));
          toast.error(errorMessage);

          throw new Error(errorMessage); // Передайте ошибку выше в компонент
        }
      }
    );
  }

  function logout() {
    return createAsyncThunk(`${name}/logout`, function (arg, { dispatch }) {
      dispatch(authActions.setAuth(null));
      localStorage.removeItem("auth");
      history.navigate("/login");
    });
  }

  function resetPassword() {
    return createAsyncThunk(
      `${name}/resetPassword`,
      async function ({ token, email }, { dispatch }) {
        dispatch(alertActions.clear());
        try {
          const response = await fetchWrapper.post(
            `http://api.nudehub.art/api/users/${email}/confirm?token=${token}`
          );
          return response;
        } catch (error) {
          const errorMessage = error || "An unknown error occurred";
          dispatch(alertActions.error({ message: errorMessage }));
          toast.error(errorMessage);
        }
      }
    );
  }
}
