import React, { useContext, useEffect, useState } from "react";
import Switcher from "./components/Switcher";
import LoginForm from "./components/LoginForm";
import SignUp from "./components/SignUp";
import ForgotPasswordForm from "./components/ForgotPasswordForm"; // Новая форма
import { ThemeContext } from "../../components/theme-context";

const Login = () => {
  const { boyTheme, toggleTheme } = useContext(ThemeContext);

  const [activeForm, setActiveForm] = useState("login");

  useEffect(() => {
    document.body.classList.toggle("boy-theme", boyTheme);
  }, [boyTheme]);

  return (
    <section>
      <Switcher activeForm={activeForm} setActiveForm={setActiveForm} />
      {activeForm === "login" && <LoginForm setActiveForm={setActiveForm} />}
      {activeForm === "signup" && <SignUp />}
      {activeForm === "forgotPassword" && <ForgotPasswordForm />}{" "}
      {/* Новая форма */}
    </section>
  );
};

export default Login;
