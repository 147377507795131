import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchWrapper from "../helpers/fetch-wrapper";
import { alertActions } from "./alert.slice";

const name = "support";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const supportActions = { ...slice.actions, ...extraActions };
export const supportReducer = slice.reducer;

function createInitialState() {
  return {
    status: "idle",
    error: null,
  };
}

function createReducers() {
  return {
    setLoading: (state) => {
      state.status = "loading";
    },
    setError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  };
}

function createExtraActions() {
  return {
    sendToSupport: createSendToSupport(),
  };

  function createSendToSupport() {
    return createAsyncThunk(
      `${name}/sendToSupport`,
      async function ({ email, reportText }, { dispatch }) {
        debugger;
        try {
          const response = await fetchWrapper.post(
            `https://api.nudehub.art/api/support`,
            { email, reportText }
          );
          return response;
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "An unknown error occurred";
          dispatch(supportActions.setError(errorMessage));
          dispatch(alertActions.error({ message: errorMessage }));
        }
      }
    );
  }
}
