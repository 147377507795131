import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchWrapper from "../helpers/fetch-wrapper";
import { alertActions } from "./alert.slice";

// create slice

const name = "users";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.item = action.payload; // Устанавливаем данные пользователя
    },
    setError(state, action) {
      state.error = action.payload; // Устанавливаем ошибку
    },
    setBalance(state, action) {
      state.balance = action.payload; // Устанавливаем баланс
    },
  },
  extraReducers,
});

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    list: null,
    item: null,
    loading: false,
    error: null,
    balance: null,
  };
}

function createExtraActions() {
  const baseUrl = "https://api.nudehub.art/api/users";

  return {
    register: register(),
    fetchUserInfo: fetchUserInfo(),
  };

  function register() {
    return createAsyncThunk(
      `${name}/register`,
      async (user) =>
        await fetchWrapper.post("https://api.nudehub.art/api/users", user)
    );
  }

  function fetchUserInfo() {
    return createAsyncThunk(
      `${name}/fetchUserInfo`,
      async function (_, { dispatch }) {
        try {
          const response = await fetchWrapper.get(
            "https://api.nudehub.art/api/users/me"
          );

          if (!response) {
            throw new Error("No data received from API");
          }
          dispatch(userActions.setBalance(response.balance));
          dispatch(userActions.setUserInfo(response)); // Отправляем данные пользователя в Redux
          return response; // Возвращаем response для createAsyncThunk
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "An unknown error occurred";
          dispatch(userActions.setError(errorMessage)); // Отправляем ошибку в Redux
          dispatch(alertActions.error({ message: errorMessage }));
          throw error; // Генерируем ошибку для rejected
        }
      }
    );
  }
}

function createExtraReducers() {
  return (builder) => {
    builder
      .addCase(extraActions.fetchUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(extraActions.fetchUserInfo.fulfilled, (state, action) => {
        if (!action.payload) {
          console.error("No payload received in fulfilled action");
        }

        state.item = action.payload; // Сохраняем данные пользователя в состоянии
        state.loading = false;
      })
      .addCase(extraActions.fetchUserInfo.rejected, (state, action) => {
        console.error("Error in fetchUserInfo: ", action.error); // Логируем ошибку
        state.error = action.error.message;
        state.loading = false;
      });
  };
}
