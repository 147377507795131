// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#account {
  padding-top: calc(1 * var(--index));
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: calc(2 * var(--index));
}
`, "",{"version":3,"sources":["webpack://./src/pages/account/components/index.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,UAAU;EACV,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,2BAA2B;AAC7B","sourcesContent":["#account {\n  padding-top: calc(1 * var(--index));\n  width: 60%;\n  margin: 0 auto;\n  display: flex;\n  justify-content: start;\n  flex-direction: column;\n  gap: calc(2 * var(--index));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
